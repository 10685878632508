<template>
	<nav v-if="$vuetify.display.mdAndUp"
		 class="sticky mx-n6 px-6 py-4 d-flex justify-center align-center text-uppercase text-secondary-lighten-2 bg-primary-darken-3">
		<template v-for="(link, i) in nav" :key="i">
			<span v-if="i">•</span>
			<v-btn class="mx-4 font-weight-bold" variant="plain" :to="{name: link.name}">
				<SvgIcon size="20" :icon="link.icon" class="mr-2" />
				{{ link.text }}
			</v-btn>
		</template>
	</nav>
	
	<aside class="bg-primary-darken-2 mx-n6 pa-4 d-flex justify-center align-center" v-if="$vuetify.display.mdAndUp && $route.name !== 'lref-reservation'">
		<p class="text-center" :class="$vuetify.display.lgAndUp ? 'h7-b' : 'h8-b'">Pas encore inscrit ? Réservez votre place dès maintenant ></p>
		<BtnPrincipal icon="Voucher" :to="{ name: 'lref-reservation', hash: '#formulaire' }" :class="$vuetify.display.mdAndUp ? 'ml-4' : 'mt-6'">
			<span class="text-primary-lighten-3" :class="$vuetify.display.mdAndUp ? 'h8-b' : 'p1-b'">Je souhaite <span class="text-secondary">participer</span></span>
		</BtnPrincipal>
	</aside>
	
	<div class="mobileBtn pa-2" v-if="$vuetify.display.smAndDown && $route.name !== 'lref-reservation'">
		<v-btn color="secondary" elevation="12" rounded class="d-flex text-primary-darken-3" :to="{ name: 'lref-reservation', hash: '#formulaire' }">
			<span class="p1-b">Je m'inscris</span>
			<SvgIcon class="ml-2" size="20" icon="Voucher" />
		</v-btn>
	</div>
</template>

<script>
export default {
	data() {
		return {
			nav: [
				{ name: 'lref', text: 'Accueil', icon: 'Home' },
				{ name: 'lref-reservation', text: 'Inscription', icon: 'Voucher' },
				{ name: 'lref-tables', text: 'Jeux & Tables', icon: 'D20' },
				{ name: 'lref-partenaires', text: 'Partenaires', icon: 'Partner' },
				{ name: 'lref-contact', text: 'Contact', icon: 'Email' },
			]
		}
	},
}
</script>

<style lang="sass" scoped>
.sticky
	position: sticky
	top: 0
	left: 0
	z-index: 10
	
	.v-btn
		opacity: 1
		transition: color 100ms
		
		&:hover
			color: rgb(var(--v-theme-secondary)) !important
			
.mobileBtn
	position: fixed
	bottom: 0
	right: 0
	z-index: 10
</style>
