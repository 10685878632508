export const routes = [
	{
		path: '/',
		name: 'home',
		redirect: { name: 'lref' }
	},
	{
		path: '/les-rats-en-folie',
		name: 'lref',
		component: () => import('../views/lref/Index'),
		meta: { layout: 'LayoutLREF' }
	},
	{
		path: '/les-rats-en-folie/reservation',
		name: 'lref-reservation',
		component: () => import('../views/lref/Reservation'),
		meta: { layout: 'LayoutLREF' }
	},
	{
		path: '/les-rats-en-folie/tables',
		name: 'lref-tables',
		component: () => import('../views/lref/Tables'),
		meta: { layout: 'LayoutLREF' }
	},
	{
		path: '/les-rats-en-folie/partenaires',
		name: 'lref-partenaires',
		component: () => import('../views/lref/Partenaires'),
		meta: { layout: 'LayoutLREF' }
	},
	{
		path: '/les-rats-en-folie/contact',
		name: 'lref-contact',
		component: () => import('../views/lref/Contact'),
		meta: { layout: 'LayoutLREF' }
	},
]
