<template>
	<img :src="icons[icon]"
		 :alt="icon"
		 onload="SVGInject(this)"
		 :width="size"
		 :height="size"
		 :style="`min-width: ${size}px; min-height: ${size}px`">
</template>

<script>
import SVGInject from '@iconfu/svg-inject'

export default {
	name: 'Icon',
	props: {
		icon: {
			type: String,
			default: ''
		},
		size: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			icons: '',
			SVGInject
		}
	},
}
</script>

<style scoped>

</style>
