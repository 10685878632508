<template>
	<v-dialog :model-value="visible"
			  max-width="1200"
			  min-height="200"
			  @update:model-value="$emit('update:visible', $event)">
		<v-card :class="$vuetify.display.mdAndUp ? 'pa-8' : 'pa-4'" max-width="1200" min-height="200">
			<v-row no-gutters>
				<v-col :cols="$vuetify.display.mdAndUp ? 4 : 12" class="d-flex align-center justify-center" :class="$vuetify.display.mdAndUp ? 'pr-8' : 'pb-4'">
					<v-img class="sandwich" aspect-ratio="1" src="/assets/sandwitch.png" alt="Photo de la médiathèque" cover />
				</v-col>
				<v-col :cols="$vuetify.display.mdAndUp ? 8 : 12" class="d-flex flex-column">
					<h1 class="mb-4 text-grey-100" :class="$vuetify.display.mdAndUp ? 'h5-b text-center' : 'h7-b'">Restauration sur site</h1>
					<p>Vous aurez la possibilité de vous restaurer sur place. Des repas seront proposés le :</p>
					<ul class="mb-4" :class="$vuetify.display.mdAndUp ? 'pl-16' : 'pl-8'">
						<li>Vendredi soir (sandwich)</li>
						<li>Samedi midi & soir (friterie et sandwich)</li>
						<li>Dimanche midi (sandwich)</li>
					</ul>
					<p>Des sandwichs végétariens seront également disponibles. Si vous avez un régime spécial, vous pouvez le préciser lors de <router-link class="text-secondary text-decoration-underline clickable" :to="{name: 'lref-reservation'}">votre inscription</router-link>.</p>
					<p class="mb-4"><router-link class="text-secondary text-decoration-underline clickable" :to="{name: 'lref-contact'}">Contactez-nous</router-link> si vous avez des questions.</p>
					<p class="font-italic text-primary-lighten-2">Le tarif des repas n'excèdera pas 5€</p>
					<v-spacer />
					<div class="mt-8 d-flex justify-center">
						<BtnPrincipal @click="$emit('update:visible', false)">
							<span class="text-primary-lighten-3" :class="$vuetify.display.mdAndUp ? 'h8-b' : 'p1-b'">Fermer</span>
						</BtnPrincipal>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		visible: Boolean,
	},
	emits: ['update:visible'],
	data() {
		return {
			dialog: true
		}
	}
}
</script>

<style lang="sass" scoped>
::v-deep
	.v-overlay__scrim
		background: rgb(var(--v-theme-greyscale-999))
		opacity: .5

.v-card
	position: relative
	box-shadow: none !important
	filter: drop-shadow(0 10px 30px rgba(black, .8))
	
	.sandwich
		max-width: 350px
</style>
