// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDsAAeHVAzQe9EvkFoooS_19awtAoc7fFM',
  authDomain: 'les-rats-du-forcage.firebaseapp.com',
  databaseURL: 'https://les-rats-du-forcage-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'les-rats-du-forcage',
  storageBucket: 'les-rats-du-forcage.appspot.com',
  messagingSenderId: '636584427913',
  appId: '1:636584427913:web:6ec22b8807f8bf92de6158',
  measurementId: 'G-WTBW4YHE27'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
