<template>
	<div class="loader">
		<SvgIcon icon="Logo" />
	</div>
</template>

<script>
export default {
	name: 'PageLoader'
}
</script>

<style lang="sass" scoped>
.loader
	height: 100vh
	width: 100vw
	display: flex
	justify-content: center
	align-items: center
	
	::v-deep svg
		width: 160px
		height: 160px
		
		[class*=t-],
		[class*=ts-]
			animation: 1.5s blink linear infinite
			opacity: 0
		
		.t-t
			animation-delay: 0s
		
		.ts-tl
			animation-delay: .166s
		
		.ts-tr
			animation-delay: .333s
		
		.t-r
			animation-delay: .5s
		
		.ts-rt
			animation-delay: .666s
		
		.ts-rb
			animation-delay: .833s
		
		.t-b
			animation-delay: 1s
		
		.ts-lb
			animation-delay: 1.166s
		
		.ts-lt
			animation-delay: 1.333s
	
@keyframes blink
	50%, 90%
		opacity: 0
	
	from, to
		opacity: 1
</style>
