import { pascalCase } from 'eslint-plugin-vue/lib/utils/casing'

export function importComponents() {
	return Object.entries(importFiles(require.context('@/components', true, /.*\.vue$/)))
}

export function importSVGs() {
	return importFiles(require.context('@/assets/auto', true, /.*\.svg$/))
}

function importFiles(requireContext) {
	let files = {}
	
	requireContext.keys().forEach(fileName => {
		//removing extension converting to valid component name e.g. "./base-input.vue" to "BaseInput"
		const componentConfig = requireContext(fileName)
		const componentName = pascalCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1').replaceAll('/', '_'))
		
		//add it to comps object with dynamic name
		files[componentName] = componentConfig.default || componentConfig
	})
	return files
}
