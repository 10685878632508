import { createSSRApp } from 'vue'
import App from '@/App.vue'
import createRouter from '@/router'
import utils from '@/plugins/utils'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import createStore from '@/store'

import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutLREF from '@/layouts/LayoutLREF'
import Icon from '@/components/Icon'
import { importComponents, importSVGs } from '@/plugins/autoLoader'
import mitt from 'mitt'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

loadFonts()

const app = createSSRApp(App)

const router = createRouter(app)

app.use(utils)
app.use(createStore(app))
app.use(router)
app.use(vuetify)
app.use(createMetaManager())
app.use(metaPlugin)
app.config.globalProperties.$bus = mitt()
app.component('LayoutDefault', LayoutDefault)
app.component('LayoutLREF', LayoutLREF)

importComponents().forEach(([key, value]) => {
	app.component(key, value)
})

app.component('SvgIcon', {
	extends: Icon,
	data() {
		return {
			icons: importSVGs()
		}
	}
})

app.mount('#app')
