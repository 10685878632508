<template>
	<div class="wrapper bg-grey-999">
		<PageLoader v-if="pageLoading" />
		<v-main>
			<RouterView />
		</v-main>
	</div>
</template>

<script>
export default {
	name: 'LayoutDefault',
	props: {
		pageLoading: Boolean,
	},
}
</script>

<style lang="sass" scoped>
.wrapper
	position: relative
	height: 100vh
	width: 100vw
	z-index: 0
	
.v-main
	height: 100%
	width: 100%
	
img
	position: absolute
	inset: 0
	object-fit: cover
	width: 100%
	height: 100%
	filter: blur(4px) brightness(0.8)
	pointer-events: none
	user-select: none
	z-index: -1
</style>
