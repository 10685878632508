// Styles
import 'vuetify/styles'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import { createVuetify } from 'vuetify'

const primary = {
  'primary-darken-3': '#1C293F',
  'primary-darken-2': '#2F3E57',
  'primary-darken-1': '#42526F',
  primary: '#546687',
  'primary-lighten-1': '#7F90B0',
  'primary-lighten-2': '#A9B9D7',
  'primary-lighten-3': '#D4E0F4',
}

const secondary = {
  'secondary-darken-3': '#7E4016',
  'secondary-darken-2': '#AA561E',
  'secondary-darken-1': '#D46C26',
  secondary: '#FF822E',
  'secondary-lighten-1': '#FFA060',
  'secondary-lighten-2': '#FFBB90',
  'secondary-lighten-3': '#FBD6BF',
}

const highlight = {
  highlight: '#ffa060',
  'highlight-darken-1': '#e5746c',
  'highlight-darken-2': '#b85574',
  'highlight-darken-3': '#7e4271',
  'highlight-darken-4': '#41325f',
}

const greyscale = {
  'greyscale-999': '#1D1D1D',
  'greyscale-900': '#333333',
  'greyscale-800': '#4F5053',
  'greyscale-700': '#696B74',
  'greyscale-600': '#838794',
  'greyscale-500': '#A0A4B1',
  'greyscale-400': '#B5B8C0',
  'greyscale-300': '#C8CACF',
  'greyscale-200': '#DCDCDE',
  'greyscale-100': '#EEEEEE',
}

const status = {
  'error-darken-2': '#99001f',
  'error-darken-1': '#B00023',
  error: '#CC0029',
  'info-darken-2': '#0A70C2',
  'info-darken-1': '#0D8DF2',
  info: '#3DA3F5',
  success: '#3D8F40',
  'success-lighten-1': '#4CB351',
  'success-lighten-2': '#70C273',
  warning: '#FB8C00',
}

const commonColors = {
  surface: '#2E3E57',
  ...primary,
  ...secondary,
  ...highlight,
  ...greyscale,
  ...status
}

export default createVuetify({
  theme: {
    defaultTheme: 'admjDark',
    themes: {
      admjLight: {
        dark: false,
        colors: {
          background: greyscale['greyscale-100'],
          font: greyscale['greyscale-999'],
          ...commonColors,
        },
      },
      admjDark: {
        dark: true,
        colors: {
          background: greyscale['greyscale-900'],
          font: greyscale['greyscale-100'],
          ...commonColors,
        },
      },
    },
  },
  components,
  directives,
})
