<template>
	<v-btn flat rounded="0" class="bg-primary-darken-1" :class="small ? 'px-3 py-2' : 'px-6 py-3'" v-bind="$attrs">
		<slot />
		<SvgIcon v-if="icon"
				 class="text-primary-lighten-3"
				 :class="{'ml-4': $slots.default && !textless}"
				 :size="small ? 24 : 32"
				 :icon="icon" />
	</v-btn>
</template>

<script>
export default {
	props: {
		icon: String,
		textless: Boolean,
		small: Boolean,
	},
}
</script>

<style lang="sass" scoped>
.v-btn
	height: auto
	width: auto
	min-width: auto
</style>
