import { createStore } from 'vuex'
import { auth, app as firebaseApp } from '@/firebase/config'
import { onAuthStateChanged }  from 'firebase/auth'
import VuexEasyFirestore from 'vuex-easy-firestore'

const easyFirestore = VuexEasyFirestore(
  [],
  { logging: true, FirebaseDependency: firebaseApp }
)

export default app => {
	const vue = app.config.globalProperties
	
	const store = createStore({
		plugins: [easyFirestore],
		modules: {}
		// ... your other store data
	})
	
	// const unsub = onAuthStateChanged(auth, (user) => {
	// 	store.commit('setAuthIsReady', true)
	// 	store.commit('setUser', user)
	// 	unsub()
	// })
	
	return store
}
