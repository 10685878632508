<template>
	<metainfo>
		<meta name="msapplication-TileColor" content="#c64446" />
		<meta name="theme-color" content="#c64446" />
		<meta property="og:type" content="article" />
		<meta property="og:image" content="https://lesratsduforcage.fr/assets/lref-banner.jpg" />
		<meta property="og:image:width" content="1440" />
		<meta property="og:image:height" content="977" />
		<meta property="og:site_name" content="Les Rats En Folie" />
		<meta property="og:description" content="Festival en plein air de Jeux de Rôle et de Jeux de Société du 23 au 25 juin 2023 à Tilloy-lès-Mofflaines." />
	</metainfo>
	<v-app>
		<component :is="layout" :page-loading="pageLoading" />
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data: () => ({
		pageLoading: false,
	}),
	computed: {
		layout () {
			return (this.$route.meta?.layout || 'LayoutDefault')
		}
	},
	mounted() {
		this.$bus.on('page-loading', show => this.pageLoading = show)
	},
}
</script>

<style lang="sass">
@import "@/assets/common.sass"
</style>
