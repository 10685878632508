import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'

export default app => {
	const vue = app.config.globalProperties
	
	const router = createRouter({
		history: createWebHistory(),
		routes,
	})
	
	return router
}
