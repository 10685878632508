import moment from 'moment'

// TODO handle moment language

export default {
	install: (app) => {
		app.config.globalProperties.$moment=moment
		
		// inject a globally available $utils object
		app.config.globalProperties.$utils = {
			shortDate: date => new Date(date).toLocaleDateString('fr-FR'),
			longDateTime: date => new Date(date).toLocaleDateString('fr-FR', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}),
			getDateFromTimestamp: timestamp => new Date(timestamp.seconds * 1000).toLocaleDateString('fr-FR', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
			}),
			getDateTimeFromTimestamp: timestamp => new Date(timestamp.seconds * 1000).toLocaleDateString('fr-FR', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}),
			sortByDate: (date1, date2) => moment(date1.dateCreate).format('YYYYMMDDHHmmss') - moment(date2.dateCreate).format('YYYYMMDDHHmmss')
		}
	}
}
