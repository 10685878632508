<template>
	<div class="bg-wrapper">
		<div class="wrapper text-primary-lighten-3">
			<div class="illustration pt-2" :class="$vuetify.display.mdAndUp ? 'mb-n16' : 'mb-n8'" align="center" app>
				<v-img class="img"
					   aspect-ratio="1"
					   src="/assets/lref-banner.jpg"
					   alt="Photo illustration de l'évènement"
					   width="843"
					   :height="$vuetify.display.mdAndUp ? 572 : 300"
					   cover />
			</div>
			
			<div v-if="$vuetify.display.mdAndUp" class="customContainer pa-0" align="center" app>
				<div class="header">
					<div class="block-left" :class="$vuetify.display.lgAndUp ? 'h8-b' : 'p1-b'">
						<p class="text-uppercase text-secondary">Tilloy-lès-Mofflaines</p>
						<p>Haut-de-France • RD60</p>
					</div>
					<h1 class="text-secondary" :class="$vuetify.display.lgAndUp ? 'h2' : 'h5'">Les Rats En Folie</h1>
					<div class="block-right" :class="$vuetify.display.lgAndUp ? 'h8-b' : 'p1-b'">
						<p class="text-secondary text-uppercase">23, 24 & 25 Juin 2023</p>
						<p>1ère édition</p>
					</div>
				</div>
			</div>
			<template v-else>
				<div class="mobileHeader bg-primary-darken-3 d-flex align-center" app>
					<v-btn icon flat variant="text" @click="mobileMenuOpen = !mobileMenuOpen">
						<SvgIcon size="24" icon="Menu" class="" />
					</v-btn>
					<h1 class="h7 text-secondary text-center">Les Rats En Folie</h1>
				</div>
				<div class="bg-primary-darken-3 px-4 text-primary-lighten-3" app>
					<v-row class="p2-b" no-gutters>
						<v-col cols="6">
							<p class="text-uppercase text-secondary">Tilloy-lès-Mofflaines</p>
							<p>Haut-de-France • RD60</p>
						</v-col>
						<v-col cols="6" class="text-right">
							<p class="text-secondary text-uppercase">23, 24 & 25 Juin 2023</p>
							<p>1ère édition</p>
						</v-col>
					</v-row>
				</div>
			</template>
			<v-navigation-drawer v-if="$vuetify.display.smAndDown" v-model="mobileMenuOpen" location="top" temporary>
				<v-list>
					<v-list-item v-for="(link, i) in nav" :key="i" :to="{name: link.name}">
						<div class="d-flex align-center">
							<SvgIcon size="20" :icon="link.icon" class="mr-2" />
							{{ link.text }}
						</div>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
			
			<div class="bg-primary-darken-3 customContainer">
				<v-main>
					<RouterView />
				</v-main>
				<aside v-if="$vuetify.display.mdAndUp" id="socials" class="d-flex flex-column" app>
					<div class="sticky">
						<v-btn size="40"
							   flat
							   color="primary-lighten-3"
							   class="text-primary-darken-2"
							   data-text="Instagram"
							   icon
							   aria-label="Accéder à la page Instagram de l'évènement"
							   href="https://www.instagram.com/ratsduforcage/"
							   target="_blank">
							<SvgIcon size="24" icon="Instagram" />
						</v-btn>
						<v-btn size="40"
							   flat
							   color="primary-lighten-3"
							   class="text-primary-darken-2"
							   data-text="Facebook"
							   icon
							   aria-label="Accéder à la page Facebook de l'évènement"
							   href="https://www.facebook.com/ratsduforcage"
							   target="_blank">
							<SvgIcon size="24" icon="Facebook" />
						</v-btn>
						<v-btn size="40"
							   flat
							   color="primary-lighten-3"
							   class="text-primary-darken-2"
							   data-text="Twitter"
							   icon
							   aria-label="Accéder à la page Twitter de l'évènement"
							   href="https://twitter.com/ratsduforcage"
							   target="_blank">
							<SvgIcon size="24" icon="Twitter" />
						</v-btn>
						<v-btn size="40"
							   flat
							   color="primary-lighten-3"
							   class="text-primary-darken-2"
							   data-text="TikTok"
							   icon
							   aria-label="Accéder à la page TikTok de l'évènement"
							   href="https://www.tiktok.com/@ratsduforcage"
							   target="_blank">
							<SvgIcon size="24" icon="TikTok" />
						</v-btn>
					</div>
				</aside>
			</div>
			<div class="pt-6 pb-12 d-flex flex-column align-center">
				<p class="mb-6" :class="$vuetify.display.mdAndUp ? 'h8-b' : 'p1-b'">Suivez l’évènement sur les réseaux sociaux</p>
				<div class="socialsFooter">
					<v-btn size="40"
						   flat
						   color="primary-lighten-3"
						   class="text-primary-darken-2 mr-6"
						   data-text="Instagram"
						   icon
						   aria-label="Accéder à la page Instagram de l'évènement"
						   href="https://www.instagram.com/ratsduforcage/"
						   target="_blank">
						<SvgIcon size="24" icon="Instagram" />
					</v-btn>
					<v-btn size="40"
						   flat
						   color="primary-lighten-3"
						   class="text-primary-darken-2 mr-6"
						   data-text="Facebook"
						   icon
						   aria-label="Accéder à la page Facebook de l'évènement"
						   href="https://www.facebook.com/ratsduforcage"
						   target="_blank">
						<SvgIcon size="24" icon="Facebook" />
					</v-btn>
					<v-btn size="40"
						   flat
						   color="primary-lighten-3"
						   class="text-primary-darken-2 mr-6"
						   data-text="Twitter"
						   icon
						   aria-label="Accéder à la page Twitter de l'évènement"
						   href="https://twitter.com/ratsduforcage"
						   target="_blank">
						<SvgIcon size="24" icon="Twitter" />
					</v-btn>
					<v-btn size="40"
						   flat
						   color="primary-lighten-3"
						   class="text-primary-darken-2"
						   data-text="TikTok"
						   icon
						   aria-label="Accéder à la page TikTok de l'évènement"
						   href="https://www.tiktok.com/@ratsduforcage"
						   target="_blank">
						<SvgIcon size="24" icon="TikTok" />
					</v-btn>
				</div>
			</div>
			
			<span class="copyright text-primary-lighten-1 px-4 py-2">© Copyright Les Rats En Folie 2023</span>
		</div>
	</div>
</template>

<script>
import eventStructuredData from '@/assets/EventStructuredData.json'
import logoStructuredData from '@/assets/LogoStructuredData.json'

export default {
	metaInfo: {
		description: 'Festival en plein air de Jeux de Rôle et de Jeux de Société du 23 au 25 juin 2023 à Tilloy-lès-Mofflaines.',
		htmlAttrs: {
			lang: 'fr-FR'
		},
		script: [
			{ type: 'application/ld+json', json: eventStructuredData },
			{ type: 'application/ld+json', json: logoStructuredData },
			{ async: true, src: 'https://www.googletagmanager.com/gtag/js?id=G-WTBW4YHE27' },
			{ src: '/gtag.js' },
		]
	},
	props: {
		pageLoading: Boolean,
	},
	data() {
		return {
			mobileMenuOpen: false,
			nav: [
				{ name: 'lref', text: 'Accueil', icon: 'Home' },
				{ name: 'lref-reservation', text: 'Inscription', icon: 'Voucher' },
				{ name: 'lref-tables', text: 'Jeux & Tables', icon: 'D20' },
				{ name: 'lref-partenaires', text: 'Partenaires', icon: 'Partner' },
				{ name: 'lref-contact', text: 'Contact', icon: 'Email' },
			]
		}
	}
}
</script>

<style lang="sass" scoped>
.illustration
	position: sticky
	top: 0
	left: 0
	z-index: -1
	
	.img
		mask-image: radial-gradient(black 50%, transparent 70%)

.mobileHeader
	position: sticky
	top: 0
	z-index: 10
	width: 100%
	
	h1
		font-family: Azonix, sans-serif
		width: max-content
		width: 100%
		line-height: 80px
	
.customContainer
	position: relative
	max-width: max(min(1300px, calc(100vw - 16rem)), 858px)
	width: 100%
	margin: 0 auto
	padding: .5rem 1.5rem 1.5rem 1.5rem
	
	#socials
		position: absolute
		top: 0
		left: calc(100% + 1rem)
		height: 100%
		z-index: -1
		
		.sticky
			position: sticky
			top: 1rem
			left: 0
		
			.v-btn
				margin-bottom: 1rem
				
				&::before
					@media screen and (min-width: 1660px)
						content: attr(data-text)
						position: absolute
						top: 0
						left: 100%
						height: 100%
						display: flex
						align-items: center
						transition: all 225ms
						color: rgb(var(--v-theme-primary-lighten-3))
						opacity: 0
						pointer-events: none
					
				&:hover::before
					left: calc(100% + 1rem)
					opacity: 1
.socialsFooter
	.v-btn
		margin-bottom: 1rem
		
		&::before
			@media screen and (min-width: 960px)
				content: attr(data-text)
				position: absolute
				top: calc(100% - 1rem)
				left: 50%
				transform: translateX(-50%)
				height: 100%
				display: flex
				align-items: center
				transition: all 225ms
				color: rgb(var(--v-theme-primary-lighten-3))
				opacity: 0
				pointer-events: none
			
		&:hover::before
			top: 100%
			opacity: 1
	
.bg-wrapper
	position: relative
	height: 100vh
	width: 100vw
	overflow: auto
	
.wrapper
	position: relative
	z-index: 0
	background-image: linear-gradient(180deg, #c24a4b, rgb(var(--v-theme-primary-darken-2)) 1144px)
	
.v-main
	height: 100%
	width: 100%
	
img
	position: absolute
	inset: 0
	object-fit: cover
	width: 100%
	height: 100%
	filter: blur(4px) brightness(0.8)
	pointer-events: none
	user-select: none
	z-index: -1
	
.header
	position: relative
	width: max-content
	
	h1
		font-family: Azonix, sans-serif
		padding: 0 5rem
		width: max-content
		line-height: 80px
		
		&::before
			content: ''
			position: absolute
			inset: 0
			background-color: rgb(var(--v-theme-primary-darken-3))
			clip-path: polygon(4rem 0%, calc(100% - 4rem) 0%, 100% 100%, 0% 100%)
			z-index: -1
			
	[class^=block-]
		position: absolute
		bottom: 1rem
		width: max-content
		line-height: 2rem
		
	.block-left
		right: 100%
		text-align: right
		
	.block-right
		left: 100%
		text-align: left
	
	&.mobile
		width: 100%
		
		h1
			width: 100%
			padding: 0
			
			&::before
				clip-path: none
		
		[class^=block-]
			position: relative
			inset: auto
			width: 50%

.copyright
	position: absolute
	bottom: 0
	left: 0
</style>
